import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';

import { CustomObjectDataFromApi } from '@/api/customObject';
import { CustomUserProfileFields, UserProfileInner } from '@/api/types';
import { MyProfile } from '@/hooks/useMyProfile';
import {
  findEmailUserProfileField,
  findPhoneNumberUserProfileField,
} from '@/pages/Contacts/shared/utils';
import { DROPDOWN_TYPE } from '@/pages/Settings/SettingsCustomObject/shared/utils';
import {
  CustomObject,
  CustomObjectPropertyType,
} from '@/pages/Settings/SettingsCustomObject/type';
import { getFullName } from '@/utils/messaging';

import {
  getDateRangeFilter,
  getIsEmptyFilter,
  getIsNotEmptyFilter,
} from '../FilterCustomObjectData/helpers';
import {
  CustomObjectData,
  CustomObjectDataFilterField,
  CustomObjectDataFilterFormOperator,
  CustomObjectDataProperty,
} from '../type';
import { EMPTY_OPTION_VALUE, isDataDeletable } from './helpers';

export function fromApiGetContactNames(
  profile: UserProfileInner,
  t: TFunction,
  {
    emailFieldId,
    phoneNumberFieldId,
  }: { emailFieldId: string; phoneNumberFieldId: string },
) {
  const phoneNumber =
    profile.customFields?.find(
      (field) => field.companyDefinedFieldId === phoneNumberFieldId,
    )?.value || '';

  const email =
    profile.customFields?.find(
      (field) => field.companyDefinedFieldId === emailFieldId,
    )?.value || '';

  return {
    id: profile.id,
    fullName: getFullName({
      firstName: profile.firstName,
      lastName: profile.lastName,
      fallback: phoneNumber || email || t('general.untitled-label'),
    }),
    phoneNumber,
    email,
    conversationId: profile.conversationId,
  };
}

export const toApiContactSearchParams = (search: string) => {
  return [
    {
      fieldName: 'displayname',
      conditionOperator: 'Contains',
      values: [search],
      nextOperator: 'Or',
    },
    {
      fieldName: 'firstName',
      conditionOperator: 'Contains',
      values: [search],
      nextOperator: 'Or',
    },
    {
      fieldName: 'phonenumber',
      conditionOperator: 'Contains',
      // Remove spaces and + signs for phone search
      values: [search.replace(/ /g, '').replace('+', '')],
      nextOperator: 'Or',
    },
    {
      fieldName: 'email',
      conditionOperator: 'Contains',
      values: [search],
      nextOperator: 'Or',
    },
    {
      fieldName: 'lastName',
      conditionOperator: 'Contains',
      values: [search],
      nextOperator: 'And',
    },
  ];
};

export function fromApiGetCustomFieldIds(fields: CustomUserProfileFields[]) {
  return {
    phoneNumberFieldId: findPhoneNumberUserProfileField(fields)?.id,
    emailFieldId: findEmailUserProfileField(fields)?.id,
  };
}

export function fromApiGetCustomObjectData(
  customObject: CustomObject,
  data: CustomObjectDataFromApi,
  myProfile?: MyProfile,
): CustomObjectData {
  const deletable = isDataDeletable(
    data,
    myProfile?.data
      ? {
          roleType: myProfile.data.roleType,
          associatedTeamsId:
            myProfile.data.associatedTeams?.map((team) => team.id) ?? [],
          staffId: String(myProfile.data.staffId),
        }
      : undefined,
  );

  return {
    id: data.id,
    relationship: customObject.relationship,
    customObjectId: data.schema_id,
    primaryProperty: {
      name: customObject.primaryProperty.name,
      value: data.primary_property_value,
    },
    properties: customObject.properties.map((property) => {
      let propertyValue;
      switch (property.type) {
        case 'boolean':
          propertyValue =
            typeof data.property_values[property.id] === 'boolean'
              ? String(data.property_values[property.id])
              : '';
          break;
        case 'number':
        case 'decimalNumber':
          propertyValue =
            typeof data.property_values[property.id] === 'number'
              ? data.property_values[property.id]
              : '';
          break;
        default:
          propertyValue = data.property_values[property.id]
            ? data.property_values[property.id]
            : '';
      }

      return {
        name: property.name,
        value: propertyValue as string,
        id: property.id,
        type: property.type,
        isRequired: property.isRequired,
        ...(DROPDOWN_TYPE.includes(property.type) && {
          options: property.options,
        }),
      };
    }),
    contact: data.referenced_user_profile
      ? {
          id: data.referenced_user_profile.id,
          name: getFullName({
            firstName: data.referenced_user_profile.first_name,
            lastName: data.referenced_user_profile.last_name,
            fallback: '',
          }),
        }
      : undefined,
    createdOn: data.created_at,
    lastModifiedOn: data.updated_at,
    createdByStaffId: data.created_by?.sleekflow_staff_id ?? '',
    deletable,
  };
}

export function toApiCreateCustomObjectData(
  customObject: CustomObject,
  data: {
    properties: Record<string, any>;
    contact: {
      id: string;
    };
    primaryPropertyValue?: string;
  },
) {
  const properties: Record<string, any> = Object.keys(data.properties).reduce(
    (acc, id) => {
      const property = customObject.properties.find((p) => p.id === id);
      acc[id] = toApiPropertyValue(data.properties[id], property?.type);

      return acc;
    },
    {} as Record<string, any>,
  );
  const isPrimaryPropertyValueAutoGenerated =
    customObject.primaryProperty.isIdAutoGenerated;

  return {
    schema_id: customObject.id,
    property_values: properties,
    referenced_user_profile_id: data.contact.id,
    ...(!isPrimaryPropertyValueAutoGenerated && {
      primary_property_value: data.primaryPropertyValue,
    }),
  };
}

export function toApiEditCustomObjectData(
  customObjectData: CustomObjectData,
  data: {
    properties: Record<string, any>;
    contact: {
      id: string;
    };
  },
) {
  const properties: Record<string, any> = Object.keys(data.properties).reduce(
    (acc, id) => {
      const property = customObjectData.properties.find((p) => p.id === id);
      const value = data.properties[id];
      acc[id] = toApiPropertyValue(value, property?.type);

      return acc;
    },
    {} as Record<string, any>,
  );

  return {
    id: customObjectData.id,
    schema_id: customObjectData.customObjectId,
    property_values: properties,
    referenced_user_profile_id: data.contact.id,
  };
}

function toApiPropertyValue(value: any, type?: CustomObjectPropertyType) {
  if ((Array.isArray(value) && value.length === 0) || value === '') {
    return null;
  }
  if (value === EMPTY_OPTION_VALUE) return null;
  if (type === 'number' || type === 'decimalNumber') {
    return Number(value);
  }
  if (type === 'boolean') {
    return value === 'true' ? true : false;
  }
  return value;
}

export function toApiFilterGroup({
  filters,
  search,
  createDateRange,
  updateDateRange,
  createdBy,
}: {
  filters: Array<
    CustomObjectDataFilterField & {
      operator: CustomObjectDataFilterFormOperator;
    }
  > | null;
  search?: string;
  createDateRange?: Date[];
  updateDateRange?: Date[];
  createdBy?: string | null;
}) {
  if (filters?.some((filter) => !filter.operator)) {
    return [];
  }
  /*     >>>>>>>>>>>>  advanced filter groups   <<<<<<<<<<<    */
  const filterGroups = [] as {
    filters: Array<{
      field_name: string;
      operator: string;
      value: string | number | boolean | null | string[];
      is_property_value: boolean;
    }>;
  }[];

  filters &&
    filters.forEach((filter) => {
      if (filter.operator === 'isNotEmpty') {
        return filterGroups.push(...getIsNotEmptyFilter(filter));
      }
      if (filter.operator === 'isEmpty') {
        return filterGroups.push(getIsEmptyFilter(filter));
      }
      if (filter.type === 'boolean') {
        return filterGroups.push({
          filters: [
            {
              field_name: filter.field,
              operator: filter.operator === 'containsExactly' ? '=' : '!=',
              value: filter.value === 'true' ? true : false,
              is_property_value: true,
            },
          ],
        });
      }
      if (filter.type === 'singleSelection') {
        return filterGroups.push({
          filters: [
            {
              field_name: filter.field,
              operator: filter.operator === 'containsExactly' ? '=' : '!=',
              value: filter.value,
              is_property_value: true,
            },
          ],
        });
      }
      if (filter.type === 'multiSelection') {
        const selections = filter.value as string[];
        return filter.operator === 'containsAllOf'
          ? filterGroups.push(
              ...selections.map((selection) => {
                return {
                  filters: [
                    {
                      field_name: filter.field,
                      operator: 'arrayContains',
                      value: selection,
                      is_property_value: true,
                    },
                  ],
                };
              }),
            )
          : filterGroups.push({
              filters: selections.map((selection) => {
                return {
                  field_name: filter.field,
                  operator: 'arrayContains',
                  value: selection,
                  is_property_value: true,
                };
              }),
            });
      }
      filterGroups.push({
        filters: [
          {
            field_name: filter.field,
            operator: filter.operator,
            value:
              filter.type === 'number' || filter.type === 'decimalNumber'
                ? Number(filter.value)
                : filter.value,
            is_property_value: true,
          },
        ],
      });
    });

  /*    >>>>>>>>>>>>    search, create on, modified on, created by filters    <<<<<<<<<<<     */
  if (search) {
    filterGroups.push({
      filters: [
        {
          field_name: 'primary_property_value',
          operator: 'contains',
          value: search,
          is_property_value: false,
        },
      ],
    });
  }

  if (createDateRange) {
    filterGroups.push(...getDateRangeFilter(createDateRange, 'created_at'));
  }
  if (updateDateRange) {
    filterGroups.push(...getDateRangeFilter(updateDateRange, 'updated_at'));
  }
  if (createdBy) {
    filterGroups.push({
      filters: [
        {
          field_name: 'created_by',
          operator: '=',
          value: ToApiCreatedByFilterParam(createdBy),
          is_property_value: false,
        },
      ],
    });
  }

  return filterGroups;
}

export const ToApiCreatedByFilterParam = (createdBy: string) => {
  //NOTE: BE accepct empty string as indicator of record created by system
  if (createdBy === 'system') return '';
  else {
    return createdBy;
  }
};

export function formatPropertyValueToDisplay(
  property: CustomObjectDataProperty,
  t: TFunction,
): string {
  if (String(property.value).length === 0) {
    return EMPTY_OPTION_VALUE;
  }
  if (property.type === 'date' && dayjs(property.value as string).isValid()) {
    return dayjs(property.value as string)
      .format('DD MMM YYYY')
      .toString();
  }
  if (
    property.type === 'dateTime' &&
    dayjs(property.value as string).isValid()
  ) {
    return dayjs(property.value as string)
      .format('DD MMM YYYY HH:mm')
      .toString();
  }
  if (property.type === 'boolean') {
    return property.value === 'true'
      ? t('custom-object-data.table.boolean.true')
      : t('custom-object-data.table.boolean.false');
  }
  if (property.type === 'multiSelection' && property.options) {
    return property.options
      .filter((o) => (property.value as string[]).includes(o.id))
      .map((o) => o.value)
      .join(', ');
  }
  if (property.type === 'number' || property.type === 'decimalNumber') {
    return String(property.value);
  }
  if (property.type === 'singleSelection' && property.options) {
    const option = property.options.find((o) => o.id === property.value);
    return option?.value ? String(option.value) : EMPTY_OPTION_VALUE;
  }

  return String(property.value);
}

export function formatDateToDisplay(date: string, format: string) {
  return dayjs(date).isValid()
    ? dayjs(date).format(format).toString()
    : EMPTY_OPTION_VALUE;
}
